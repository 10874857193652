import React from 'react';

const footerItems = [{
    text: 'Terms of Service',
    link: 'https://www.media.auledge.com/web/Auledge_TC.pdf'
}, {
    text: 'Privacy Policy',
    link: 'https://www.media.auledge.com/web/Auledge_PP.pdf'
}];

const footerItems2 = [{
    text: 'Auledge © 2022',
    link: 'https://www.auledge.com'
}, {
    text: 'Instagram',
    link: 'https://www.instagram.com/auledge.app'
}, {
    text: 'LinkedIn',
    link: 'https://www.linkedin.com/company/auledge'
}];

const FooterItem = ({
    item,
    index
}) => {
    let itemStyle = 'py-4';
    index > 0 && (itemStyle += ' md:ml-8'); // add left-margin if not first item

    return (
        <div className={itemStyle}>
            <h3>
                <a className="text-white" href={item.link}>
                    {item.text}
                </a>
            </h3>
        </div>
    );
}

export default () => {
    return (
        <div className="md:flex md:justify-center items-center mt-8 p-4 bg-orange-400 min-w-xl">
            <div className="md:flex">
                {footerItems2.map((item, index) => {
                    return (
                        <FooterItem 
                            item={item}
                            index={index} 
                        />
                    )
                })}
            </div>
            <div className="text-white text-4xl px-10 font-bold hidden md:block">
                •
            </div>
            <div className="md:flex">
                {footerItems.map((item, index) => {
                    return (
                        <FooterItem 
                            item={item}
                            index={index} 
                        />
                    )
                })}
            </div>
        </div>
    );
}