import React from 'react';
import FeatureItem from './FeatureItem';

// {
//   iconName: "mic",
//   title: "Voice-controlled",
//   description: "Navigate the content with your voice"
// }

const features = [{
    iconName: "flash",
    title: "Genuine",
    description: "Hear people for who they are"
  },{
    iconName: "planet",
    title: "Personalized",
    description: "Discover content picked just for you"
  },{
    iconName: "construct", 
    title: "Effortless",
    description: "Record with a sound studio in your pocket"
  }];

export default () => {
    return (
        <div className="grid gap-y-8 grid-cols-2 py-8 grow mb-6">
            {features.map(({ iconName, title, description }) => (
            <FeatureItem 
                iconName={iconName}
                title={title}
                description={description}
                key={`feature-${iconName}`}
            />
            ))}
        </div>
    );
}