import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

const PROD = 'https://sheltered-island-41614.herokuapp.com';
// const PROD = 'https://salty-coast-73419.herokuapp.com';
const LOCAL = 'http://localhost:4000/graphql';

const client = new ApolloClient({
  uri: PROD,
  cache: new InMemoryCache()
});

export { client };