import React, { useState, useEffect } from 'react';
// import mockup from '../../static/auledge-mockup.gif';
import mockup from '../../static/auledge-mockup-new.jpg';
import { useMutation } from '@apollo/client';
import queueBetaTesterMutation from '../../queries/queueBetaTester';
import jsonp from 'jsonp';
import queryString from 'query-string';
import logo from '../../static/auledge-logo-orange-cropped.png';
import ReactGA from 'react-ga4';

import NavigationBar from '../generic/NavigationBar';
import FeatureGrid from '../landing/FeatureGrid';
import FeatureItem from '../landing/FeatureItem';
import EAForm from '../landing/EAForm';
import FooterSimple from '../generic/FooterSimple';
import axios from 'axios';
import appstore from '../../static/appstore.png';
import google from '../../static/google.png';
import styles from "../landing/Modal.module.css";

const LandingPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  const [email, setEmail] = useState('');
  const [blank, setBlank] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [queueBetaTester] = useMutation(queueBetaTesterMutation, {
    onCompleted(res) {
      ReactGA.event("early_access_signup")
    },
    onError(err) {
      console.log('error');
      console.log(err.message);
    }
  });

  const formData = {
    EMAIL: email
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // record email with Mailchimp
    if (email.trim() !== '') {
      queueBetaTester({
        variables: {
          email: email.trim()
        }
      });
      
      axios.post(`https://salty-coast-73419.herokuapp.com/ea/${email}`, {
        email: email.trim()
      })
      .then(res => {
        console.log('success');
        console.log(res);
        setEmail('');
        setSubmitted(true);
      })
      .catch(err => {
        console.log('fail');
        console.log(err);
      });

      console.log('record email: ', email);
    }
  }
  
  const renderText = () => {
      return submitted ? "You're all set!" : "Join the waitlist!"
  }

  return (
    <div className="md:h-screen flex flex-col">
      <div className="flex justify-center pt-10 grow"> 
        <div className="flex flex-col max-w-3xl p-4 md:pr-10 justify-around">
          <div>
            <div className="mx-auto text-center md:text-left mb-4">
              <div className="md:inline-flex items-center py-8">
                <img src={logo} alt="Auledge" className="w-16 mb-4 md:mr-8 mx-auto" />
                <h1 className="font-bold bg-blue-500">
                  Auledge
                </h1>
              </div>
              <h2 className="font-bold">
                People beyond pictures
              </h2>
              {/* <a href="https://auledge.medium.com/hi-from-auledge-e88f4a8d88b7">
                <p className="py-8 font-bold">
                  Learn more &#8594;
                </p>
              </a> */}
              <div className="flex flex-col justify-around inline-block py-8" style={{display: 'inline'}}>
                  <a href="https://apps.apple.com/ua/app/auledge/id1574373999"><img src={appstore} alt="Appstore Link" width="47%" className="marg-b-m marg-t-sm marg-r-sm block md:hidden" style={{float: 'left'}}/></a>
                  <button className="block md:hidden" onClick={() => setIsOpen(true)}><img src={google} alt="Google Play Link" className="marg-t-sm block md:hidden" style={{opacity: 0.9, float: 'left'}}/></button>
              </div>
            </div>
            <img 
              src={mockup} 
              alt="App mock-up" 
              className="w-full md:hidden"
            />
            <div className="hidden md:block">
              <FeatureGrid />
            </div>
            <div className="flex flex-col justify-around inline-block" style={{display: 'inline'}}>
                <a href="https://apps.apple.com/ua/app/auledge/id1574373999"><img src={appstore} alt="Appstore Link" width="45%" className="marg-t-sm marg-r-sm hidden md:block" style={{float: 'left'}}/></a>
                <button className="hidden md:block" style={{"max-width": "204px"}} height="92%" onClick={() => setIsOpen(true)}><img src={google} alt="Google Play Link" max-width="92%" className="marg-t-sm marg-r-sm" style={{opacity: 0.9, float: 'left'}}/></button>
            </div> 
            {isOpen && <>
              <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
              <div className={styles.centered}>
                <div className={styles.modal}>
                  <div className={styles.modalHeader}>
                    <h5 className={styles.heading}>Coming to Android soon!</h5>
                  </div>
                  <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
                    <ion-icon 
                      name={'close'} 
                      size="large"
                    />
                  </button>
                  <form onSubmit={onSubmit}>
                    <input 
                      value={email} 
                      onChange={e => setEmail(e.target.value)}
                      type="email" 
                      required="required"
                      placeholder="Email"
                      className="modal display-block w-full outline-none p-6 border-2 rounded-xl mb-8 focus:border-orange-400 hover:border-orange-400 text-3xl mt-12"
                      autoComplete="off"
                      name="email"
                      style={submitted ? {
                          borderColor: "#78e08f",
                          width: '90%',
                          marginLeft: '5%',
                          color: 'black'
                      } : {
                          borderColor: "rgb(250, 152, 58)",
                          width: '90%',
                          marginLeft: '5%',
                          color: 'black'
                      }}
                    />
                    <input type="hidden" name="c" value="?" />
                    <button 
                      className="w-full p-6 text-white text-3xl rounded-xl font-bold justify-around"
                      disabled={submitted ? true : false}
                      style={(submitted 
                      ? { 
                        backgroundColor: "#78e08f",
                        width: '90%',
                        marginLeft: '5%'
                      } : {
                        backgroundColor: "rgb(250, 152, 58)",
                        width: '90%',
                        marginLeft: '5%'
                      })}
                    >
                        {renderText()}
                    </button>
                  </form>
                </div>
              </div>
            </>}
          </div>
        </div>
        <div className="hidden max-w-2xl pt-20 pl-14 justify-center md:block">
          <img 
            src={mockup} 
            alt="App mock-up" 
            className="place-self-center"
          />
        </div>
      </div>
      <div className="text-center">
        <FooterSimple />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default LandingPage;