import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import appstore from "../../static/appstore.png";
import google from "../../static/google.png";
import { getMobileOperatingSystem } from "../../services/utilities";
import { useQuery } from "@apollo/client";
import getAudioByAudioIdQuery from "../../queries/getAudioByAudioId";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import logo from '../../static/auledge-logo-orange.png';

const AudioPage = () => {
	let history = useHistory();
	let { audioId } = useParams();
	const { data, loading, error } = useQuery(getAudioByAudioIdQuery, {
		variables: {
			audioId,
		},
	});

	useEffect(() => {
		const os = getMobileOperatingSystem();
		switch (os) {
			case "iOS":
				// detected iOS
				// window.location.href = 'https://apps.apple.com/app/id1574373999';
				// history.push('/');
				break;
			case "Android":
				// detected Android
				history.push("/");
				break;
			default:
				// case: detected other (e.g. desktop browser)
				// window.location.href = "https://apps.apple.com/app/id1574373999";
				// history.push("/");
				break;
		}
	}, []);
	
	// TODO 1: fix image not displaying properly
	// TODO 2: fix link
	// TODO 3: ensure ProfilePage is fine too
	// TODO 4: add logo to top-right corner of image X
	
	return (
		<div className="page">
			{data?.getAudioByAudioId && (
				<>
					<div className="mt-20 m-auto w-96 sm:w-96 md:w-96 lg:w-96 xl:max-w-prose">
						<div className="relative">
							<img
								src={data.getAudioByAudioId.user.avatar.url}
								className="w-full aspect-square rounded-2xl mb-4"
								alt="user avatar"
							/>
							<img 
								src={logo}
								className="absolute top-2 right-2 aspect-square w-20"
								alt="logo"
							/>
						</div>
						<AudioPlayer
							autoPlay
							src={data.getAudioByAudioId.url}
							onPlay={e => console.log("onPlay")}
							showJumpControls={false}
						/>
						<p className="text-center decoration-black text-black font-bold py-4">
							{data.getAudioByAudioId.title}
						</p>
						<p className="text-center text-black">{`${data.getAudioByAudioId.user.username}`}</p>
						<a href="auledge://audio/${audioId}">
							<div className="rounded-2xl mt-8 p-6 bg-secondary">
								<p className="text-center text-white">Open in Auledge</p>
							</div>
						</a>
						<p className="mt-4">
							{data.getAudioByAudioId.user.avatar.url}
						</p>
					</div>
				</>
			)}
		</div>
	);
};

export default AudioPage;

