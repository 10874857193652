import './styles/styles.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import { client } from './client';

import ProfilePage from './components/pages/ProfilePage';
import AudioPage from './components/pages/AudioPage';
import LandingPage from './components/pages/LandingPage';
import LandingPage2 from './components/pages/LandingPage2';
import SupportPage from './components/pages/SupportPage';
import ReactGA from 'react-ga4';

// ReactGA.initialize('UA-299001200');
ReactGA.initialize('G-38KQ9L5GW6');

const App = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/user/:userId" component={ProfilePage} />
          <Route path="/audio/:audioId" component={AudioPage} />
          <Route path="/support" component={SupportPage} />
					<Route path="/" component={LandingPage2} />
          <Route path="*" component={LandingPage2} />
        </Switch>
      </ApolloProvider>
    </Router>
  );
}

export default App;
