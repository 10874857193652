import React from 'react';
import logo from '../../static/auledge-logo-orange-cropped.png';


const SupportPage = () => {

  return (
    <div className="md:h-screen flex flex-col">
      <div className="flex -aic justify-center pt-80 grow">
        <div className="mx-auto text-center md:text-left mb-4 px-20" style={{width: 700}}>
              <div className="md:inline-flex items-center py-8">
                <img src={logo} alt="Auledge" className="w-16 mb-4 md:mr-8 mx-auto" />
                <h1 className="text-center md:text-left font-bold bg-blue-500">
                  Auledge Support
                </h1>
              </div>
              <h2 className="pt-6">
              In case you have any questions about the app, you noticed any app issues, you want to provide general feedback, or request new features, please reach out to <u>team@auledge.com</u>.
              </h2>
              <a href="https://auledge.medium.com/hi-from-auledge-e88f4a8d88b7">
                <p className="py-10 font-bold">
                  Learn more &#8594;
                </p>
              </a>
            </div>
      </div>
    </div>
  );
}

export default SupportPage;