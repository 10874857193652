import { gql } from '@apollo/client';

export default gql`
  query GetAudioByAudioId($audioId: String!) @client {
    getAudioByAudioId(audioId: $audioId) {
      id
      url
      title
      user {
        id
        username
        avatar {
					id
          url
        }
      }
    }
  }
`;
