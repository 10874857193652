import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import appstore from "../../static/appstore.png";
import google from "../../static/google.png";
import { getMobileOperatingSystem } from "../../services/utilities";
import { useQuery } from "@apollo/client";
import getProfQuery from "../../queries/getProf";

const ProfilePage = () => {
	let history = useHistory();
	let { userId } = useParams();
	const { data, loading, error } = useQuery(getProfQuery, {
		variables: {
			userId,
		},
	});

	useEffect(() => {
		const os = getMobileOperatingSystem();
		
		// TODO: fallback link for a href?
		switch (os) {
			case "iOS":
				// detected iOS
				// window.location.href = 'https://apps.apple.com/app/id1574373999';
				// history.push('/');
				try {
					window.location.href = `auledge://user/${userId}`;
				} catch (err) {
					window.location.href = 'https://apps.apple.com/app/id1574373999';
				}
				break;
			case "Android":
				// detected Android
				history.push("/");
				break;
			default:
				// case: detected other (e.g. desktop browser)
				window.location.href = "https://apps.apple.com/app/id1574373999";
				history.push("/");
				break;
		}
	}, []);

	return (
		<div className="page">
			{data?.getProf && (
				<a href={`auledge://user/${userId}`}>
					<div className="mt-20 m-auto w-96 sm:w-96 md:w-96 lg:w-96 xl:max-w-prose">
						<img
							src={data.getProf.avatar.url}
							className="w-full aspect-square rounded-2xl"
							alt="user avatar"
						/>

						<p className="text-center decoration-black text-black font-bold py-4">
							{`${data.getProf.firstName} ${data.getProf.lastName}`}
						</p>
						<p className="text-center text-black">{`${data.getProf.username}`}</p>

						<div className="rounded-2xl mt-8 p-6 bg-secondary">
							<p className="text-center text-white">Open in Auledge</p>
						</div>
					</div>
				</a>
			)}
		</div>
	);
};

export default ProfilePage;

