import React from 'react';

export default ({ 
    iconName, 
    title, 
    description 
}) => {
    return (
      <div className="border-l-4 border-orange-400 px-8 text-left">
          <ion-icon 
            name={iconName} 
            size="large"
          />
          <h3 className="py-4 font-bold">
            {title}
          </h3>
        <p>
          {description}
        </p>
      </div>
    );
  }