import { gql } from '@apollo/client';

export default gql`
	query GetProf($userId: String!) {
		getProf(userId: $userId) {
			id
			username
			firstName
			lastName
			avatar {
				id
				url
			}
		}
	}
`;
